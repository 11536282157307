<template>
    <section id="dashboard-analytics">
        <b-row>
            <b-col md="4">
                <b-link :to="{
              name: 'ca-applications',
              params: { stage: 'APPLIED' }
            }">
                    <statistic-card-vertical
                        color="primary"
                        icon="FileIcon"
                        :statistic="i18nT(`Application`)"
                        :statistic-title="i18nT(`Your applications`)"
                    />
                </b-link>
            </b-col>
            <b-col md="4">
                <b-link :to="{
              name: 'tasks',
              params: { software: currentSoftware },
            }">
                    <statistic-card-vertical
                        color="primary"
                        icon="CheckCircleIcon"
                        :statistic="i18nT(`Tasks`)"
                        :statistic-title="i18nT(`Your tasks`)"
                    />
                </b-link>
            </b-col>
            <b-col md="4">
                <b-link :to="{
              name: 'inbox-all',
              query: {
                      newMsg: 1
                  }
            }">
                    <statistic-card-vertical
                        color="primary"
                        icon="MailIcon"
                        :statistic="i18nT(`Message`)"
                        :statistic-title="i18nT(`Send new message`)"
                    />
                </b-link>
            </b-col>
        </b-row>

        <b-row class="match-height">
            <b-col lg="4">
                <b-card no-body>
                    <b-card-header>
                        <b-card-title class="ml-25">
                            {{applications.count}} {{i18nT(`Applications`)}}
                        </b-card-title>
                    </b-card-header>

                    <!-- timeline -->
                    <b-card-body>
                        <b-media
                            no-body
                            v-for="application in applications.items"
                            :key="application.Id"
                            class="media pb-50 pt-50 border-bottom"
                        >
                            <b-media-body
                                v-if="application.OfferObj"
                                class="my-auto"
                            >
                                <b-link :to="{name: 'ca-application', params: {id: application.OfferObj.Id}}">
                                    <h6 class="mb-0 text-primary">
                                        {{ application.OfferObj.Label }}
                                    </h6>
                                </b-link>
                                <p
                                    v-if="application.OfferObj"
                                    class="mb-0"
                                    v-html="truncate(application.OfferObj.Description, 30)"
                                >
                                </p>
                            </b-media-body>
                        </b-media>
                        <b-media
                            style="text-align: center;"
                            v-if="data.applications && data.applications.length === 0"
                        >
                          <span class="b-avatar badge-light-secondary rounded mb-2 mt-4" style="width: 70px; height: 70px;">
                                <feather-icon  icon="ClipboardIcon" size="36"/>
                          </span>
                            <h5>{{i18nT(`Nothing new`)}}</h5>
                            <p class="text-muted"> {{i18nT(`There are no applications.`)}}</p>
                        </b-media>
                        <!--/ timeline -->
                    </b-card-body>
                    <b-link
                        :to="{
                          name: 'ca-applications',
                          params: { stage: 'APPLIED' },
                        }"
                        style="padding: 10px;"
                    >
                        <b-button
                            variant="outline-secondary"
                            style="width: 100%"
                        >
                            {{i18nT(`See all`)}}
                        </b-button>
                    </b-link>
                </b-card>
            </b-col>
            <b-col lg="4">
                <b-card no-body>
                    <b-card-header>
                        <b-card-title class="ml-25">
                            {{tasks.count}} {{i18nT(`Tasks`)}}
                        </b-card-title>
                    </b-card-header>

                    <!-- timeline -->
                    <b-card-body>
                        <b-media
                            no-body
                            v-for="task in tasks.items"
                            :key="task.Id"
                            class="media pb-50 pt-50 border-bottom"
                        >
                            <b-media-body class="my-auto">
                                <b-link :to="{
                                        name: 'task-view',
                                        params: { software: currentSoftware, id: task.Id }
                                    }">
                                    <h6 class="mb-0 text-primary">
                                        {{ task.Label }}
                                    </h6>
                                </b-link>
                                <p
                                    class="mb-0"
                                    v-html="truncate(task.Description, 30)"
                                />
                            </b-media-body>
                        </b-media>
                        <b-media
                            style="text-align: center;"
                            v-if="tasks && tasks.count === 0"
                        >
                              <span class="b-avatar badge-light-secondary rounded mb-2 mt-4" style="width: 70px; height: 70px;">
                                <feather-icon icon="CheckCircleIcon" size="36" />
                              </span>
                            <h5>{{i18nT(`Nothing new`)}}</h5>
                            <p class="text-muted"> {{i18nT(`There are no tasks.`)}}</p>
                        </b-media>
                        <!--/ timeline -->
                    </b-card-body>
                    <b-link :to="{ name: 'tasks', params: { software: currentSoftware }}" style="padding: 10px;">
                        <b-button
                            variant="outline-secondary"
                            style="width: 100%"
                        >
                            {{i18nT(`See all`)}}
                        </b-button>
                    </b-link>
                </b-card>
            </b-col>
            <b-col lg="4">
                <analytics-messages
                    :data="messages"
                    manageLink="/ca/inbox"
                />
            </b-col>
        </b-row>
    </section>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BLink,
    BButton,
    BBadge
} from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import AnalyticsCongratulation from './analytics/AnalyticsCongratulation.vue'
import AnalyticsMessages from './analytics/AnalyticsMessages.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StaticCardFA'
import useAuth from '@/auth/useAuth'
import _ from 'lodash'

export default {
    components: {
        BRow,
        BCol,
        AnalyticsCongratulation,
        AnalyticsMessages,
        StatisticCardVertical,
        BCard,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BMedia,
        BMediaBody,
        BLink,
        BButton,
        BBadge
    },
    data() {
        return {
            data: {},
            surveys: [],
            suggestions: {
                items: [],
                count: 0
            },
            messages: {
                messages: [],
                count: 0
            },
            tasks: {
                items: [],
                count: 0
            },
            applications: {
                items: [],
                count: 0
            }
        }
    },
    computed: {
        currentSoftware() {
            return useAuth.getCurrentSoftware()
        }
    },
    created() {
        this.$http.get(`main/caDashboard`).then(({ data }) => {
            this.data = data.data
            this.messages = {
                messages: data.data.messages,
                count: data.data.messages_count
            }
            this.suggestions = {
                items: data.data.suggestions,
                count: data.data.suggestions_count
            }
            this.tasks = {
                items: data.data.tasks,
                count: data.data.tasks_count
            }
            this.applications = {
                items: data.data.applications,
                count: data.data.applications_count
            }
        })
    },
    methods: {
        kFormatter,
        truncate: function(data, num) {
            if (!data) return ''
            const reqdString = data
                .split(' ')
                .slice(0, num)
                .join(' ')
            return data.length > reqdString.length ? reqdString + '...' : reqdString
        }
    }
}
</script>
