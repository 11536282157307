var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"dashboard-analytics"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-link',{attrs:{"to":{
          name: 'ca-applications',
          params: { stage: 'APPLIED' }
        }}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"FileIcon","statistic":_vm.i18nT("Application"),"statistic-title":_vm.i18nT("Your applications")}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-link',{attrs:{"to":{
          name: 'tasks',
          params: { software: _vm.currentSoftware },
        }}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"CheckCircleIcon","statistic":_vm.i18nT("Tasks"),"statistic-title":_vm.i18nT("Your tasks")}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-link',{attrs:{"to":{
          name: 'inbox-all',
          query: {
                  newMsg: 1
              }
        }}},[_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"MailIcon","statistic":_vm.i18nT("Message"),"statistic-title":_vm.i18nT("Send new message")}})],1)],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',{staticClass:"ml-25"},[_vm._v(" "+_vm._s(_vm.applications.count)+" "+_vm._s(_vm.i18nT("Applications"))+" ")])],1),_c('b-card-body',[_vm._l((_vm.applications.items),function(application){return _c('b-media',{key:application.Id,staticClass:"media pb-50 pt-50 border-bottom",attrs:{"no-body":""}},[(application.OfferObj)?_c('b-media-body',{staticClass:"my-auto"},[_c('b-link',{attrs:{"to":{name: 'ca-application', params: {id: application.OfferObj.Id}}}},[_c('h6',{staticClass:"mb-0 text-primary"},[_vm._v(" "+_vm._s(application.OfferObj.Label)+" ")])]),(application.OfferObj)?_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.truncate(application.OfferObj.Description, 30))}}):_vm._e()],1):_vm._e()],1)}),(_vm.data.applications && _vm.data.applications.length === 0)?_c('b-media',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"b-avatar badge-light-secondary rounded mb-2 mt-4",staticStyle:{"width":"70px","height":"70px"}},[_c('feather-icon',{attrs:{"icon":"ClipboardIcon","size":"36"}})],1),_c('h5',[_vm._v(_vm._s(_vm.i18nT("Nothing new")))]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.i18nT("There are no applications.")))])]):_vm._e()],2),_c('b-link',{staticStyle:{"padding":"10px"},attrs:{"to":{
                      name: 'ca-applications',
                      params: { stage: 'APPLIED' },
                    }}},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.i18nT("See all"))+" ")])],1)],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',{staticClass:"ml-25"},[_vm._v(" "+_vm._s(_vm.tasks.count)+" "+_vm._s(_vm.i18nT("Tasks"))+" ")])],1),_c('b-card-body',[_vm._l((_vm.tasks.items),function(task){return _c('b-media',{key:task.Id,staticClass:"media pb-50 pt-50 border-bottom",attrs:{"no-body":""}},[_c('b-media-body',{staticClass:"my-auto"},[_c('b-link',{attrs:{"to":{
                                    name: 'task-view',
                                    params: { software: _vm.currentSoftware, id: task.Id }
                                }}},[_c('h6',{staticClass:"mb-0 text-primary"},[_vm._v(" "+_vm._s(task.Label)+" ")])]),_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.truncate(task.Description, 30))}})],1)],1)}),(_vm.tasks && _vm.tasks.count === 0)?_c('b-media',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"b-avatar badge-light-secondary rounded mb-2 mt-4",staticStyle:{"width":"70px","height":"70px"}},[_c('feather-icon',{attrs:{"icon":"CheckCircleIcon","size":"36"}})],1),_c('h5',[_vm._v(_vm._s(_vm.i18nT("Nothing new")))]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.i18nT("There are no tasks.")))])]):_vm._e()],2),_c('b-link',{staticStyle:{"padding":"10px"},attrs:{"to":{ name: 'tasks', params: { software: _vm.currentSoftware }}}},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.i18nT("See all"))+" ")])],1)],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('analytics-messages',{attrs:{"data":_vm.messages,"manageLink":"/ca/inbox"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }